export enum DOCUMENT_TYPE {
  KYC = 'kyc',
  POF = 'pof',
}

export enum KYC_STATUS {
  REQUIRED = 'Required',
  COMPLETE = 'Completed',
  AWAITING_PHOTO_ID = 'Awaiting Photo ID',
}

export enum KYC_DOCUMENT_STATUS {
  PASS = 'Pass',
  FAIL = 'Fail',
}
