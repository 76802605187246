import axios from 'axios'
import ScriptLoader from 'components/Furniture/ScriptLoader'
import StyleLoader from 'components/Furniture/StyleLoader'
import environment from 'configuration/env'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { isChineseRegion } from '../../utils/getRegion'
import { TranslationKeyPrefix, useTranslations } from 'hooks/useTranslations'

const getAssetUrl = (path: string): string => {
  let [baseEnvironment] = environment.name.split('_')

  // in case of DEV (localhost) environment, use the staging asset url
  if (baseEnvironment === 'development') {
    baseEnvironment = 'staging'
  }
  // in case of TEST environment, use the integration asset url
  if (baseEnvironment === 'test') {
    baseEnvironment = 'integration'
  }

  return `${environment.dslAssetsUrl}/design-system-library/${baseEnvironment}/${path}`
}

const getStyleSheet = (path: string, onError: (url: string) => void): JSX.Element => (
  <StyleLoader url={getAssetUrl(path)} onError={onError} />
)

const getScript = (
  path: string,
  isModule: boolean,
  onError: (url: string) => void
): JSX.Element => <ScriptLoader url={getAssetUrl(path)} isModule={isModule} onError={onError} />

const FurnitureScripts = ({
  onError,
  language,
}: {
  onError: (url: string) => void
  language: string
}): JSX.Element | null => {
  const [headerData, setHeaderData] = useState(undefined)
  const [footerData, setFooterData] = useState(undefined)
  const { getTranslationLiteral: tl } = useTranslations(TranslationKeyPrefix.LiveChat)

  const fetchHeader = () => {
    axios
      .get(`${environment.dotComUrl}/api/discoverywebsite/header?language=${language}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'content-type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        setHeaderData(response.data)
      })
      .catch((e) => {
        if (onError) onError(e.message)
      })
  }

  const fetchFooter = () => {
    axios
      .get(`${environment.dotComUrl}/api/discoverywebsite/footer?language=${language}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'content-type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        setFooterData(response.data)
      })
      .catch((e) => {
        if (onError) onError(e.message)
      })
  }

  useEffect(() => {
    fetchHeader()
    fetchFooter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return headerData && footerData ? (
    <>
      {getScript('christies-design-system-library.esm.js', true, onError)}
      {getScript('christies-design-system-library.js', false, onError)}

      {getStyleSheet('atlas.css', onError)}
      {getStyleSheet('bembo.css', onError)}
      {getStyleSheet('arizona.css', onError)}
      {getStyleSheet('christies-design-system-library-header-footer.css', onError)}
      {!isChineseRegion() &&
        getStyleSheet('christies-design-system-library-live-chat.css', onError)}
      <Script
        id="chrGlobalData"
        dangerouslySetInnerHTML={{
          __html: `window.chrGlobal = window.chrGlobal || {}; window.chrGlobal.labels = { close_modal_txt: '${tl(
            'close'
          )}' };`,
        }}
      ></Script>

      <Script
        id="chrHeaderData"
        dangerouslySetInnerHTML={{
          __html: `window.chrComponents = window.chrComponents || {}; window.chrComponents.header = ${JSON.stringify(
            headerData
          )}`,
        }}
      ></Script>

      <Script
        id="chrFooterData"
        dangerouslySetInnerHTML={{
          __html: `window.chrComponents = window.chrComponents || {}; window.chrComponents.footer = ${JSON.stringify(
            footerData
          )}`,
        }}
      ></Script>
    </>
  ) : null
}

export default FurnitureScripts
